import styled, { css } from "styled-components";
import theme from "common/style/styleSetup";
export const Header = styled.div`
  // background-color: #050511;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* body .navbar-overlay { */
  /* @media screen and (max-width: 768px) {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0; */
  /* width: 100%;
      height: 100%; */
  /* width: 100vw;
    height: 100vh; */
  /* visibility: visible; */
  /* opacity: 1; */
  /* transition: 0.3s; */
  /* } */
  /* } */
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    margin: 0px;
  }
`;
export const Row = styled.div`
  display: flex;
`;
export const Menu = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const Info = styled.div`
  color: yellow;
  margin-left: 30px;
  cursor: pointer;
  img {
    width: 20px;
  }
  span {
    color: white;
  }
`;
export const Content = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  filter: brightness(0.85);
  border: 3px groove yellow;
  border-radius: 10px;
  min-width: 100px;
  justify-content: center;
  p {
    margin: 0;
    font-weight: 300;
  }
  padding: 5px 8px;
  margin: 2px;
  span {
    color: white !important;
  }
  :hover {
    filter: brightness(1.5);
  }

  ::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 100%;
    background-color: olive;
    transform: skewX(-1deg);
    transform-origin: right center;
    opacity: 0;
    left: -1em;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0 0 0.4em olive;
  }
  :hover::before,
  :hover::after {
    opacity: 0.9; /* Set opacity to 0 to disable the shadow effect */
  }
  cursor: pointer;
  :hover p {
    font-size: 18px;
  }
`;
export const ContentLogOut = styled.div`
  display: flex;
  height: 30px;
  width: 100px;
  justify-content: center;
  align-items: center;
  filter: brightness(0.85);
  border: 3px groove red;
  border-radius: 10px;
  p {
    margin: 0;
    font-weight: 300;
  }
  padding: 5px;
  margin: 2px;
  span {
    color: white !important;
  }
  :hover {
    filter: brightness(1.5);
  }

  ::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 100%;
    background-color: #800000;
    transform: skewX(-1deg);
    transform-origin: right center;
    opacity: 0;
    left: -1em;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0 0 0.4em #800000;
  }
  :hover::before,
  :hover::after {
    opacity: 0.9; /* Set opacity to 0 to disable the shadow effect */
  }
  cursor: pointer;
  :hover p {
    font-size: 18px;
  }
`;
export const AvatarImg = styled.div`
  text-algin: center;
  background: white;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  justify-content: center;
  display: flex;
  margin: 0px;
  img {
    width: 35px;
    height: 35px;
    margin-top: 3px;
    cursor: pointer;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  border-top: solid 1px 1px ${theme.bg[9]};
  border-bottom: solid 1px 1px ${theme.bg[9]};
  background-image: linear-gradient(#23344a, #13253e);
  box-shadow: inset 0 3px 0 #0e151e;
`;

export const WrapperTop = styled.div`
  max-width: 1350px;
  width: 100%;
  height: 55px;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0 5px 0 15px;
  gap: 10px;
  margin-bottom: 50px;
  z-index: 100;
  @media screen and (max-width: 1250px) {
    width: 100%;
    height: 180px;
    justify-content: center;
    flex-direction: column;
    padding: 0px;
    margin: 0 0 0 0;
    gap: 0px;
    row-gap: 20px;
  }
  @media screen and (max-width: 768px) {
    height: 120px;
    flex-direction: row;
  }
  position: static;
`;

export const MenuWraper = styled.div`
  /* padding: 12px 12px;
  background-color: white;
  border: 4px solid green; */
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
export const MenuGroupList = styled.div<{ show?: boolean | undefined }>`
  /* padding: 12px 12px;
  background-color: white;
  border: 4px solid green; */
  /* @media screen and (min-width: 768px) {
    display: none;
  } */
  ${(props) =>
    props.show &&
    css`
      @media screen and (max-width: 768px) {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        z-index: 4;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        visibility: visible;
        opacity: 1;
        transition: 0.3s;
      }
    `}
`;

export const TopLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 100%;
    animation: logoAkAnim 8s ease infinite;
    height: 80px;
    @media screen and (max-width: 424px) {
      height: 60px;
    }
  }
`;
export const TopButton = styled.div<{ show?: boolean | undefined }>`
  display: flex;
  gap: 8px;
  .content-top {
    /* height: 25px; */
    font-weight: 600;
    color: ${theme.color[0]};
    justify-content: center;
    display: flex;
    align-items: center;
    /* margin: 0 auto; */
    padding: 1px 8px 0;
    border-radius: 4px;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
    &.logout {
      background: ${theme.bg[11]};
      border: solid 1px ${theme.bg[14]};
      border: none;
    }
    :hover {
      // background: ${theme.bg[13]};
      transform: scale(1.2);
      cursor: pointer;
      &.logout {
        background: ${theme.bg[12]};
      }
    }
    .fa {
      margin: -3px 4px 0 0;
    }
  }
  .content-meta{
    border: 2px solid red;
    border-radius:13px;
    cursor: pointer;
    display: flex;
    margin: 0 6px;
    transition: all 0.3s ease-in-out;
    .span {
      padding: 5px 8px;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      background: #fe642e;
      border-radius: 12px;
      border: 2px solid green;
    }
    :hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1250px) {
    // display: none;
  }
  @media screen and (max-width: 768px) {
    display: none;
    ${(props) =>
      props.show &&
      css`
        display: flex;
        position: fixed;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 24px;
        align-items: flex-start;
        top: 0;
        padding: 24px;
        left: 1px;
        width: max-content;
        height: 100%;
        z-index: 5;
        background-color: black;
      `}
  }
`;

export const WrapperBot = styled.div`
  max-width: 1350px;
  width: 100%;
  height: 46px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: 0 auto;
  .item {
    color: ${theme.color[7]};
    width: 10%;
    height: 24px;
    padding: 0 12px;
    text-align: center;
    border-left: 1px solid ${theme.bg[15]};
    justify-content: center;
    display: flex;
    align-items: center;
    :nth-child(10) {
      border-right: 1px solid ${theme.bg[15]};
    }
    .content {
      width: 100%;
      height: 28px;
      padding-top: 1px;
      line-height: 28px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      :hover {
        background: ${theme.bg[16]};
        border: solid 1px ${theme.bg[17]};
      }
    }
  }
`;

export const BtnMenu = styled.div`
  cursor: pointer;
  margin-left: 24px;
`;

export const BoxItem = styled.div``;
export const BoxItemP = styled.p``;
