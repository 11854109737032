import styled from 'styled-components'
export const Footers = styled.div`
  margin: 24px 0;
  width: 100%;
  font-size: 12px;
  color: #8b8c8f;
  line-height: 19px;
  text-align: center;
  span {
    margin: 0 3px;
    color: #fbbd01;
  }
`
