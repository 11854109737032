import React, { useRef, useState, useEffect } from "react";
import Web3 from "web3";
import { ethers } from 'ethers'

// import process from "../router/env";
const web3 = new Web3(window.ethereum);

const getNetworkId = async () => {
  const currentChainId = await web3.eth.net.getId();
  // console.log(currentChainId);
  return currentChainId;
};

const setupNetwork = async () => {
  const provider = window.ethereum;
  // console.log(process.env)

  if (provider) {
    const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10) || 1;
    // const chainId = process.env.REACT_APP_CHAIN_ID || 97
  // console.log(chainId)

    const currentChainId = await getNetworkId();
    // console.log(chainId, currentChainId);
    if (currentChainId !== chainId) {
      // console.log(Web3.utils.toHex(chainId));
      try {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(chainId) }],
        });
      } catch (switchError) {
        // console.log("switchError", switchError.code === 4902);
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          // console.log("wallet_addEthereumChain", chainId);
          // alert("add this chain id");
          try {
            // console.log("wallet_addEthereumChain", chainId);
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: Web3.utils.toHex(chainId),
                  chainName: process.env.REACT_APP_BLOCKCHAIN_NAME,
                  nativeCurrency: {
                    name: process.env.REACT_APP_BLOCKCHAIN_CURRENCY_NAME,
                    symbol: process.env.REACT_APP_BLOCKCHAIN_SYMBOL,
                    decimals: process.env.REACT_APP_BLOCKCHAIN_DECIMALS
                  },
                  rpcUrls: ['https://goerli-rollup.arbitrum.io/rpc'],
                  blockExplorerUrls: ['https://goerli.arbiscan.io/']
                }
              ]
            })
            return true;
          } catch (error) {
            console.log("error connect 1", error);
            console.error(error);
            // return "wrong network";
            return false;
          }
        }
      }
    }
  } else {
    console.log("error connect");
  }
  return Number(provider?.networkVersion);
};

export default setupNetwork;
