/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react' //lazy
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import btnPartner from 'assets/images/button/btn-partner.png'
import btnAddValue from 'assets/images/button/btn-addvalue.png'
import btnWithDraw from 'assets/images/button/btn-withdraw.png'
import btnNoti from 'assets/images/button/btn-noti.png'
import btnMess from 'assets/images/button/btn-adminmess.png'
import btnUser from 'assets/images/button/Button-2.png'
import btnMenu from 'assets/images/button/ButtonMenu.png'
import { UrlInternal } from 'common/constants/endpoints'
import logo from 'assets/images/login/logo.png'
import { Popover } from 'antd'
import detectEthereumProvider from '@metamask/detect-provider'
import setupNetwork from '../../hooks/setupNetwork'
import Web3 from 'web3'
import { ethers } from 'ethers'

import './style.css'
import avatar from 'assets/images/avatar.png'
import * as S from './style'
import changePoint from 'assets/images/Icon.png'
interface Props {
  onClick?: any
  className?: any
}

// console.log('123', process)

//  async checkMetamaskHasConnected() {
//     if (window.ethereum) {
//       window.ethereum.on('connect', () => {
//       console.log("Metamask connected")
//       this.setState({ metamaskHasConnected: true })
//   })
// }

const HeaderPage = (props: Props) => {
  const history = useHistory()
  const web3 = new Web3(window.ethereum)
  const { onClick, className } = props
  const dataMe = useSelector((state: any) => state.user)
  const [onShowMenu, setOnShowMenu] = useState<boolean>(false)
  const [accountEthe, setAccountEthe] = useState('')
  const [destinationAddress, setDestinationAddress] = useState('')
  const [chainID, setChainID] = useState('')
  const [networkID, setNetwordID] = useState('')
  const [balance, setBalance] = useState(0)
  const handleShow = () => {
    setOnShowMenu(!onShowMenu)
  }
  const Logout = () => {
    localStorage.removeItem('User')
    localStorage.removeItem('Popup')
    history.push(UrlInternal.LOGIN)
  }

  useEffect(() => {}, [dataMe])

  // useEffect(() => {
  //   if (window?.ethereum) {
  //     addWalletListener()
  //     addNetworkListener()
  //     connectWallet()
  //     // TestWallet()
  //   }
  // })

  const addWalletListener = async () => {
    if (typeof window != 'undefined' && typeof window.ethereum != 'undefined') {
      window.ethereum.on('accountsChanged', async (accounts: any) => {
        const balance = await web3.eth.getBalance(accounts[0])
        setAccountEthe(accounts[0])
        setBalance(Number(balance) / Math.pow(10, 18))
      })
    } else {
      // Metamask is not installed
      setAccountEthe('')
      console.log('Please install metamask')
    }
  }
  // const TestWallet = async () => {
  //   if (typeof window != 'undefined' && typeof window.ethereum != 'undefined') {
  //     try {
  //       const accounts = await window.ethereum.request({
  //         method: 'eth_accounts'
  //       })
  //       const balance = await web3.eth.getBalance(accounts[0])
  //       const newData = Number(balance) / Math.pow(10, 18)
  //       console.log('metamask', balance, newData)
  //     } catch (err) {
  //       console.error('err', err)
  //     }
  //   } else {
  //     console.log('Please install metamask')
  //   }
  // }

  const addNetworkListener = async () => {
    if (typeof window != 'undefined' && typeof window.ethereum != 'undefined') {
      const web3 = new Web3(window.ethereum)
      const currentChainId: any = await web3.eth.net.getId()
      // console.log(currentChainId);
      setNetwordID(currentChainId)
      window.ethereum.on('chainChanged', async (chainID: any) => {
        const accounts = await window.ethereum.request({
          method: 'eth_accounts'
        })
        const balance = await web3.eth.getBalance(accounts[0])
        setBalance(Number(balance) / Math.pow(10, 18))
        setChainID(chainID)
      })
    } else {
      // Metamask is not installed
      setChainID('')
      console.log('Please install metamask')
    }
  }
  const connectWallet = async () => {
    if (typeof window != 'undefined' && typeof window.ethereum != 'undefined') {
      try {
        // Metamask is install
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts'
        })
        const chainId = await window.ethereum.request({
          method: 'eth_chainId'
        })
        const balance = await web3.eth.getBalance(accounts[0])
        setChainID(chainId)
        setBalance(Number(balance) / Math.pow(10, 18))
        setAccountEthe(accounts[0])
      } catch (err) {
        console.error(err)
      }
    } else {
      console.log('Please install metamask')
    }
  }

  // console.log(chainID, balance, networkID, accountEthe)

  const onConnect = async () => {
    const metaConnect = window.ethereum
    if (metaConnect && metaConnect?.isMetaMask) {
      const accounts = await metaConnect.request({
        method: 'eth_requestAccounts'
      })
      const account = accounts[0]
      if (accountEthe) {
        const network: any = await setupNetwork()
        if (network === process.env.REACT_APP_CHAIN_ID) {
          console.log('network')
          setAccountEthe(accountEthe)
        }
      } else {
        const accounts = await metaConnect.request({
          method: 'eth_requestAccounts'
        })
        const account = accounts[0]
        setAccountEthe(account)
      }
    } else {
      if (window.confirm('Setup MetaMask')) {
        window.open('https://metamask.io/download/', '_blank')
      }
    }
  }

  const content = (
    <div style={{ margin: '0px 10px', minWidth: '250px' }}>
      <S.Row>
        {dataMe.dataUser && (
          <S.Avatar onClick={() => history.push(UrlInternal.USER)}>
            <S.AvatarImg>
              <img src={avatar} alt='avatar' />
            </S.AvatarImg>
            <p style={{ color: '#FAAD14' }}>{dataMe.dataUser.mb_name}</p>
          </S.Avatar>
        )}
        <S.Info>
          <p>
            보유머니: <span>{dataMe.dataUser.mb_money}</span> 원
          </p>
          <S.BoxItemP onClick={() => history.push(UrlInternal.POINT)}>
            포인트: <span>{dataMe.dataUser.mb_point}</span> 점
            <img src={changePoint} alt='changePoint' /> 전환
          </S.BoxItemP>
        </S.Info>
      </S.Row>
      <S.Menu>
        <S.Content onClick={() => history.push(UrlInternal.CUSTOMER_CENTER)}>
          <p style={{ color: 'yellow' }}>고객센터</p>
        </S.Content>
        <S.Content onClick={() => history.push(UrlInternal.MESSAGE)}>
          <p style={{ color: 'yellow' }}>쪽지</p>
        </S.Content>
        <S.ContentLogOut onClick={Logout}>
          <p style={{ color: 'red' }}>로그아웃</p>
        </S.ContentLogOut>
      </S.Menu>
    </div>
  )
  const contentMetamask = (
    <div style={{ margin: '0px 10px', minWidth: '250px' }}>
      {!accountEthe ? (
        <S.Menu>
          <S.Content onClick={onConnect}>
            <p style={{ color: 'yellow' }}>Connect MetaMask</p>
          </S.Content>
        </S.Menu>
      ) : Number(networkID) !== Number(process.env.REACT_APP_CHAIN_ID) ? (
        <S.Menu>
          <S.Content onClick={onConnect}>
            <p style={{ color: 'yellow' }}>Change Network</p>
          </S.Content>
        </S.Menu>
      ) : (
        <>
          <S.Info>
            <p>
              Wallet:
              <span>
                {accountEthe?.substring(0, 10)}...{accountEthe?.substring(32)}
              </span>
            </p>
            <p>
              BNB: <span>{balance}</span>
            </p>
          </S.Info>
          <S.Menu>
            <S.Content onClick={() => history.push(UrlInternal.DEPOSIT_TOKEN)}>
              <p style={{ color: 'yellow' }}>Token Deposit</p>
            </S.Content>
            <S.Content onClick={() => history.push(UrlInternal.PAYMENT_TOKEN)}>
              <p style={{ color: 'yellow' }}>Token Withdraw</p>
            </S.Content>
          </S.Menu>
        </>
      )}
    </div>
  )

  return (
    <S.Header className='navbar-overlay'>
      {/* <S.Wrapper> */}
      <S.WrapperTop>
        <S.MenuWraper>
          {/* <MenuOutlined
            style={{ fontSize: '32px', color: '#08c' }}
            onClick={handleShow}
          > */}
          <S.BtnMenu onClick={handleShow}>
            <img alt='' src={btnMenu} />
          </S.BtnMenu>
          {/* </MenuOutlined> */}
        </S.MenuWraper>
        <S.TopLogo onClick={() => history.push(UrlInternal.HOME)}>
          <img alt='logo' src={logo} />
        </S.TopLogo>
        <S.MenuGroupList
          onClick={handleShow}
          show={onShowMenu}
        ></S.MenuGroupList>
        <S.TopButton show={onShowMenu}>
          {dataMe?.dataUser?.mb_option_rec === '1' && (
            <S.BoxItem
              className='content-top'
              onClick={() => window.open(UrlInternal.PARTNER)}
            >
              <img alt='' src={btnPartner} />
            </S.BoxItem>
          )}
          <S.BoxItem
            className='content-top'
            onClick={() => history.push(UrlInternal.DEPOSIT)}
          >
            <img alt='' src={btnAddValue} />
          </S.BoxItem>
          <S.BoxItem
            className='content-top'
            onClick={() => history.push(UrlInternal.PAYMENT)}
          >
            <img alt='' src={btnWithDraw} />
          </S.BoxItem>
          <S.BoxItem
            className='content-top'
            onClick={() => history.push(UrlInternal.NOTIFICATION)}
          >
            <img alt='' src={btnNoti} />
          </S.BoxItem>

          <S.BoxItem
            className='content-top'
            onClick={() => history.push(UrlInternal.MESSAGE)}
          >
            <img alt='' src={btnMess} />
          </S.BoxItem>
          <div
            className='content-top'
            // onClick={() => history.push(UrlInternal.MESSAGE)}
          >
            <Popover
              content={content}
              arrow={false}
              placement='bottomRight'
              trigger='click'
              id='custom-popover'
            >
              <img alt='' src={btnUser} />
            </Popover>
          </div>
          {/* <div
            className='content-meta'
            // onClick={() => history.push(UrlInternal.MESSAGE)}
          >
            <span className='span'>
              <Popover
                content={contentMetamask}
                arrow={false}
                placement='bottomRight'
                trigger='click'
                id='custom-popover'
              >
                MetaMask
              </Popover>
            </span>
          </div> */}
        </S.TopButton>
      </S.WrapperTop>
      {/* </S.Wrapper> */}
      {/* <S.Wrapper>
        <S.WrapperBot>
          {headerMenu.map((v: any, i: number) => {
            return (
              <div className="item" key={i}>
                <div className="content" onClick={() => history.push(v.link)}>
                  {v.label}
                </div>
              </div>
            );
          })}
        </S.WrapperBot>
      </S.Wrapper> */}
    </S.Header>
  )
}

export default HeaderPage
