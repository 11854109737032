import React from 'react'
import './style.css'
import logo from 'assets/images/login/logo.png'
// import logo from 'assets/images/login/World_Logo.png'
interface Props {
  overlay?: any
}

export default function Loading(props: Props) {
  const { overlay } = props

  return (
    <div className='bg-loading'>
      <div className='content-loading'>
        <div className='border-loading'></div>
        {/* <div className='icon-loading'>bigpeakgame</div> */}
        <div className='icon-loading'>
          <img
            className='icon-loading'
            src={logo}
            alt='loading-logo'
            style={{ maxWidth: '500px' }}
          />
        </div>
        {/* <div className='icon-loading'>
          <img
            className='icon-loading'
            src={logo}
            alt='loading-logo'
            style={{ width: '500px' }}
          />
        </div> */}
      </div>
    </div>
  )
}
